import {initializeShortcodes} from './shortcodes.jsx';

import Alpine from 'alpinejs';


// Initialize the application
document.addEventListener("DOMContentLoaded", () => {

    window.Alpine = Alpine

    // Register shortcodes
    let contentToCheckForShortcodes = document.body; // This is just an example, replace with actual content
    initializeShortcodes(contentToCheckForShortcodes).then(r => loadApplication());

    document.querySelectorAll('.page-width--narrow').forEach(function (el) {
        if (el.querySelectorAll('div[data-shortcode]').length > 0) {
            el.style.maxWidth = 'var(--page-width)';
        }
    });
});

function loadApplication() {
    // Event listeners
    // document.addEventListener("change", Filters.handleFilterChange);
    // document.addEventListener("click", UI.handleUIClicks);
    //
    // // Cart logic
    // handleCartLogic();
}

// function handleCartLogic() {
//     const currentUrl = getCurrentUrl();
//
//     if (currentUrl.includes("/cart")) {
//         // Load cart items from local storage
//         const cartItems = safeJSONParse(localStorage.getItem("savedIds"), {});
//         const cartQuantities = safeJSONParse(localStorage.getItem("savedIds2"), {});
//
//         API.loadCartItems(cartItems).then(data => {
//             UI.displayCartItems(data, cartQuantities);
//             UI.updateCartTotal(data, cartQuantities);
//         });
//
//         // Attach event listeners specific to cart logic
//         document.querySelector(".removeId").addEventListener("click", UI.handleCartItemRemoval);
//         document.querySelector("#checkout").addEventListener("click", UI.handleCheckout);
//     }
// }
