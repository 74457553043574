import {h, render} from 'preact';
import {fetchCustomCss} from './api.js';
import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';

const SHORTCODES_MAPPING = {};

const API_BASE_URL =  process.env.VITE_API_BASE_URL || 'http://localhost:8000'

async function loadShortcodeModules() {
    const context = import.meta.glob('./shortcodes/*.jsx');
    for (const path in context) {
        const module = await context[path]();
        SHORTCODES_MAPPING[module.shortcode] = module.default;
    }
}

async function replaceShortcodesWithComponents(bodyElement) {
    let shortcodeExists = true;
    for (const shortcode in SHORTCODES_MAPPING) {
        const elements = bodyElement.querySelectorAll(`[data-shortcode="${shortcode}"]`);
        if (elements.length > 0) {
            if (shortcodeExists) {
                shortcodeExists = false;
                Sentry.init({
                    dsn: "https://f9a236380acd9f68876659effca4c7e6@o483019.ingest.sentry.io/4505766071959552",
                    whitelistUrls: [
                        /https:\/\/cdn\.boughtofeed\.co\.uk\//
                    ],
                    beforeSend(event, hint) {
                        if (hint.originalException instanceof Error) {
                            const stackTrace = hint.originalException.stack;
                            if (!stackTrace.includes('cdn.boughtofeed.co.uk')) {
                                return null; // Drop the event and don't send to Sentry
                            }
                        }
                        return event; // Send the event to Sentry
                    },
                    integrations: [
                        new Sentry.BrowserTracing({
                            tracePropagationTargets: [API_BASE_URL],
                        }),
                        new Sentry.Replay(),
                    ],
                    tracesSampleRate: 1.0,
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                });
            }
        }

        for (const el of elements) {
            el.style.display = 'block';

            // Attach a shadow root to the element
            const shadowRoot = el.attachShadow({mode: 'open'});

            // Fetch and append custom CSS
            const data = await fetchCustomCss();
            let style = document.createElement("style");
            style.innerHTML = data;
            shadowRoot.appendChild(style);

            // Include the external stylesheet in the Shadow DOM
            const styleLink = document.createElement('link');
            styleLink.rel = 'stylesheet';
            styleLink.href = 'https://cdn.boughtofeed.co.uk/shopify/assets/app.css';
            // styleLink.href = 'http://localhost:5173/resources/css/app.css';
            shadowRoot.appendChild(styleLink);

            // Set the font-size for the rem unit inside the Shadow DOM
            const remStyle = document.createElement('style');
            remStyle.textContent = `:host > * { font-size: 16px; }`;
            shadowRoot.appendChild(remStyle);

            // Create a container for the Preact component inside the Shadow DOM
            const container = document.createElement('div');
            container.className = 'boughto';
            shadowRoot.appendChild(container);

            const ShortcodeComponent = SHORTCODES_MAPPING[shortcode];

            // Extract data attributes as props
            const props = { ...el.dataset };

            // Render the Preact component inside the container within the Shadow DOM
            render(<ShortcodeComponent {...props}/>, container);
            Alpine.initTree(shadowRoot);

            // Check if container--narrow has a child with class boughto
            if (el.closest('.container--narrow')) {
                el.closest('.container--narrow').style.maxWidth = 'none';
            }
        }
    }
}


export async function initializeShortcodes(bodyElement) {
    await loadShortcodeModules();
    await replaceShortcodesWithComponents(bodyElement);
}
